@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;800&family=Raleway:wght@300;400;500;600;700;800;900&display=swap');

@layer base{
    body {
        @apply font-[Raleway]
    }
    li {
        @apply p-4;
        @apply cursor-pointer
    }
}

.content-div{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
    width: 100%;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        rgba(112, 157, 255, 0.8),
        hsla(242, 74%, 61%, 0.8)
    );
}